<template>
  <v-col
    :style="cssProps"
  >
    <v-row>
      <v-col
        col="12"
        :class="{'px-0':$vuetify.breakpoint.smAndDown}"
      >
        <!-- Billing Frequency and Country Settings -->
        <div class="justify-center px-0 d-flex flex-column flex-md-row justify-md-space-between align-center px-md-4">
          <div
            style="opacity:0; width: 100px"
          />
          <v-btn-toggle
            v-model="isYearly"
            mandatory
            color="primary"
            class="d-flex"
          >
            <v-btn
              width="170"
              :value="false"
            >
              <span>{{ $t('products.monthly') }}<br></span>
            </v-btn>
            <v-btn
              width="170"
              :value="true"
              class="no-uppercase"
            >
              <span><span class="text-uppercase">{{ $t('products.yearly') }}<br></span>
                <span>({{ $t('products.discount') }})</span>
              </span>
            </v-btn>
          </v-btn-toggle>
          <div
            style="width: 100px"
            class="mt-4 mt-md-0"
          >
            <v-select
              v-if="allowCountrySelection"
              v-model="dynamicCountryCode"
              :items="availableCountryCodes"
              outlined
              hide-details
              dense
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center d-flex">
      <v-col
        v-for="product of filteredProducts"
        :key="product.productName"
        cols="12"
        :md="productItemWidth"
        class="pa-md-4"
      >
        <v-card
          class="ribbon-box"
          :class="{'highlight': product.productName === currentPackage}"
        >
          <v-card-text>
            <!-- RIBBON -->
            <div
              v-if="ribbon(product)"
              class="ribbon-1 right"
            >
              <span
                class="font-weight-bold white--text"
                :class="{'text-subtitle-1':$vuetify.breakpoint.mdAndUp}"
              >{{ $t(ribbon(product)) }}</span>
            </div>

            <div class="justify-center d-flex">
              <PackageLogo
                :current-package="product.productName"
                :show-label="false"
                class="pt-3"
              />
              <h3 class="mt-2 text-center font-weight-black text-h5">
                {{ product.productName }}
              </h3>
            </div>
            <div class="mb-2">
              <div
                v-for="highlight of product.highlightText"
                :key="highlight"
                class="justify-center d-flex"
              >
                <v-icon>mdi-check</v-icon>
                <span>{{ $t(highlight) }}</span>
              </div>
              <div
                class="px-4 mt-8 text-center price-wrapper"
              >
                <div class="price">
                  {{ priceFormatted(product) }}
                </div>
                <p
                  class="text-caption"
                  v-html="$t(priceDescription(product.priceInfo))"
                />
              </div>

              <div class="mb-4 text-center cta-wrapper">
                <v-btn
                  v-if="isPackageChange || product.productName === 'LIGHT'"
                  type="button"
                  color="primary"
                  class="w-full"
                  :outlined="isOutlinedButton(product.productName)"
                  :disabled="isButtonDisabled(product.productName)"
                  @click="selectProduct(product)"
                >
                  {{ $t(buttonText(product)) }}
                </v-btn>
                <v-btn
                  v-else
                  type="button"
                  color="primary"
                  :href="calendlyLink"
                  target="_blank"
                  class="w-full"
                  rel="noopener noreferrer"
                >
                  {{ $t('products.button-text.check') }}
                </v-btn>
                <p
                  v-if="product.priceInfo.hint"
                  class="mt-2"
                >
                  {{ product.priceInfo.hint }}
                </p>
              </div>
              <v-list
                dense
                :style="{'min-height': isPackageChange ? '150px' : '250px'}"
              >
                <v-list-item
                  v-for="feature of getTeaserFeatures(product)"
                  :key="feature"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-check-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span v-html="$t(feature)" />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-expand-transition>
                <v-list
                  v-show="expandAllFeatures[product.productName]"
                  dense
                  class="mt-n4"
                >
                  <v-list-item
                    v-for="feature of getExtendedFeatures(product)"
                    :key="feature"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <span v-html="$t(feature)" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
              <div class="justify-center d-flex">
                <v-btn
                  text
                  color="primary"
                  @click="expandAllFeatures[product.productName] = !expandAllFeatures[product.productName]"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >
                    {{ expandAllFeatures[product.productName] ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                  </v-icon>
                  {{ $t('products.button-text.info') }}
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import currencyMixin from '@/mixins/currency'
import { products, isDowngrade, isUpgrade } from '@/lib/product'
import PackageLogo from '@/components/PackageLogo'
import branding from '@/mixins/branding'

export default {
  components: {
    PackageLogo
  },
  mixins: [currencyMixin, branding],
  props: {
    countryCode: {
      type: String,
      required: true,
      validator: (value) => value.toUpperCase() === value
    },
    isPackageChange: {
      type: Boolean,
      default: false
    },
    currentPackage: {
      type: String,
      default: null
    },
    hideDowngrade: {
      type: Boolean,
      default: false
    },
    allowCountrySelection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isYearly: true,
      expandAllFeatures: {},
      dynamicCountryCode: this.countryCode
    }
  },
  computed: {
    productItemWidth () {
      return this.filteredProducts.length === 1 ? 12 : this.filteredProducts.length === 2 ? 6 : 4
    },
    filteredProducts () {
      if (this.hideDowngrade) {
        return products[this.platform].filter(product => !isDowngrade(this.currentPackage, product.productName))
      }
      return products[this.platform]
    },
    title () {
      if (this.$auth?.user?.name) {
        return this.$t('products.choose-with-name', { name: this.$auth.user.name })
      } else {
        return this.$t('products.choose')
      }
    },
    priceDescription () {
      return (product) => this.isYearly ? product.yearly.label : product.monthly.label
    },
    calendlyLink () {
      return 'https://calendly.com/bottimmo-marketing-experten/marketing-beratung?utm_source=register-form&utm_medium=login-app&utm_campaign=sales"'
    },
    priceFormatted () {
      return (product) => {
        const price = product?.priceInfo[this.getBillingFrequency].price?.[this.getVariant]
        return this.formatPrice(price, this.getCurrency())
      }
    },
    getVariant () {
      // array can be added by other country codes, if they have different prices
      if (['CH'].includes(this.dynamicCountryCode)) {
        return this.dynamicCountryCode
      }
      return 'default'
    },
    getBillingFrequency () {
      return this.isYearly ? 'yearly' : 'monthly'
    },
    cssProps () {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary
      }
    },
    availableCountryCodes () {
      return [
        { text: 'EUR', value: 'DE' },
        { text: 'CHF', value: 'CH' }
      ]
    }
  },
  watch: {
    isYearly () {
      localStorage.setItem('registration.billing.isYearly', this.isYearly)
    }
  },
  mounted () {
    this.initExpandAllFeatures()
  },
  methods: {
    initExpandAllFeatures () {
      const products = this.filteredProducts.reduce((acc, product) => {
        acc[product.productName] = false
        return acc
      }, {})

      this.expandAllFeatures = Object.assign({}, products)
    },
    isOutlinedButton (productName) {
      return this.isPackageChange && isDowngrade(this.currentPackage, productName)
    },
    isButtonDisabled (productName) {
      return this.isPackageChange && this.currentPackage === productName
    },
    buttonText (product) {
      if (this.isPackageChange) {
        if (isUpgrade(this.currentPackage, product.productName)) return 'products.button-text.upgrade'
        if (isDowngrade(this.currentPackage, product.productName)) return 'products.button-text.downgrade'
        return 'products.button-text.current'
      }
      return product.buttonText
    },
    getTeaserFeatures (product) {
      const amountOfTeaserFeatures = this.isPackageChange ? 3 : 5
      return product.features.slice(0, amountOfTeaserFeatures)
    },
    getExtendedFeatures (product) {
      const amountOfTeaserFeatures = this.isPackageChange ? 3 : 5
      return product.features.slice(amountOfTeaserFeatures)
    },
    ribbon (product) {
      return product.ribbon && product.ribbon[this.getBillingFrequency]
    },
    selectProduct (product) {
      // TODO planVariantIds from billwerk feature should be considered and be used here
      this.$emit('productSelected', { ...product, isYearly: !!this.isYearly })
    },
    getCurrency () {
      return { DE: 'EUR', AT: 'EUR', CH: 'CHF' }[this.dynamicCountryCode]
    },
    getPrice (billingFrequency = null) {
      if (!billingFrequency) {
        billingFrequency = this.getBillingFrequency
      }
      const prices = this.planVariants[billingFrequency].prices
      const price = prices[this.dynamicCountryCode] || prices.default
      return this.isYearly ? (price / 12) : price
    }
  }
}
</script>
<style scoped>
.price {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 8px;
}
.price-wrapper {
  min-height: 90px;
}
.no-uppercase {
  text-transform: unset !important;
}

.ribbon-1 {
  position: absolute;
  background: var(--primary-color);
  box-shadow: 0 0 0 999px var(--primary-color);
  clip-path: inset(0 -100%);
}

.right {
  inset: 0 0 auto auto;
  transform-origin: 0 0;
  transform: translate(29.3%) rotate(45deg);
}

.ribbon-box {
  position:relative;
  overflow: hidden;
}

.highlight {
  box-shadow: 0px 0px 10px var(--primary-color) !important;
}
</style>
